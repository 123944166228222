import { Menu, Button, Layout, Tag, Form, Input, Checkbox  } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  PieChartOutlined,
  VideoCameraAddOutlined,
  UserOutlined,
  FileImageOutlined,
  BorderOuterOutlined
} from '@ant-design/icons';
import React, { useState } from 'react';
import Home from './Home';
import ImageObjectDemo from './ImageObjectDemo';
import FaceDetection from './FaceDetection';
import FaceRecognition from './FaceRecognition.js';
import AadhaarVerification from './AadhaarVerification';
import CamFeed from './CamFeed';

const { SubMenu } = Menu;

const { Header, Footer, Sider, Content } = Layout;
const { innerWidth: width, innerHeight: height } = window;

export default function Dashboard() {

  const [collapsed, setCollapsed] = useState(true);
  const [key, setKey] = useState('0');
  const [contentMargin, setContentMargin] = useState(80);

  const toggleCollapsed = (key) => {
    setCollapsed(!collapsed);
    setContentMargin(collapsed ? 200: 80);
  }

  const componentsSwitch = (key) => {
    switch (key) {
      case '0':
        return (<Home></Home>);
        
      case '1': 
        return (<ImageObjectDemo></ImageObjectDemo>);

      case '2': 
        return (<FaceDetection></FaceDetection>);

      case '3': 
        return (<FaceRecognition></FaceRecognition>);

      case '4': 
        return (<AadhaarVerification></AadhaarVerification>);
      
      case '5': 
        return (<CamFeed></CamFeed>);

      default:
        break;
     }
    };

  return (
    <Layout>
      <Sider
       style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
        }}
        trigger={null} 
        collapsible 
        collapsed={collapsed}>
          <div style={{height: "32px",
               margin: "16px",
               background: "#001529"}} />
        <Menu
          defaultSelectedKeys={['0']}
          defaultOpenKeys={['sub1']}
          mode="inline"
          theme="dark"
          inlineCollapsed={collapsed}
          onSelect={(e) => setKey(e.key)}
        >
          <Menu.Item key="0" icon={<HomeOutlined />}>
            Home
          </Menu.Item>
          <SubMenu key="sub1" icon={<PieChartOutlined />} title="Demos">
            <Menu.Item key="4" icon={<UserOutlined />}> Aadhaar Verification</Menu.Item>
            <Menu.Item key="3" icon={<FileImageOutlined />}>Face Recognition</Menu.Item>
            <Menu.Item key="5" icon={<VideoCameraAddOutlined />}>Cam Feed</Menu.Item>
            <Menu.Item key="1" icon={<BorderOuterOutlined />}>Object Detection</Menu.Item>
            {/* <Menu.Item key="2" icon={<FileImageOutlined />}>Face Detection</Menu.Item> */}
          </SubMenu>
        </Menu>
        </Sider>
      <Layout style={{ marginLeft: contentMargin }}>
          <Header className="site-layout-background" 
            style={{ padding: 0, 
              background: '#001529', 
              position: 'fixed',
              overflow: 'auto',
              zIndex: 2,
              width: width,
            }}>
            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: toggleCollapsed,
              style: {'padding': '0 24px',
                'font-size': '18px',
                'line-height': '64px',
                cursor: 'pointer',
                transition: 'color 0.3s',
                color: 'white'}
            })}
            <Tag>GARUN GROUP</Tag>
          </Header>
        <Content style={{ margin: '62px 0px 0', overflow: 'initial' }}>
          {componentsSwitch(key)}
        </Content>
        <Footer style={{ textAlign: 'center' }}>Garun Group ©2022</Footer>
      </Layout>
    </Layout>
  )
}
