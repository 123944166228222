import React, { useRef, useEffect, useState } from "react";
import * as faceapi from 'face-api.js';
import * as tf from '@tensorflow/tfjs';

import Webcam from "react-webcam";
import api from '../api.js';

function FaceRecognition() {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  let descriptor = null;
  let label = 'unknown';

  //  Load Trained models
  const runFacemesh = async () => {
    try {
      const MODEL_URL = `${process.env.PUBLIC_URL}/models`;
      await faceapi.nets.ssdMobilenetv1.load(MODEL_URL);
      await faceapi.nets.faceLandmark68Net.load(MODEL_URL);
      await faceapi.nets.faceRecognitionNet.load(MODEL_URL);
      setInterval(() => {
        detect();
      }, 100);
      setInterval(() => {
        updateLabel();
      }, 1500);
    } catch(error) {
      console.log(error);
    }
  };

  const updateLabel = async () => {
    if(descriptor) {
      const dbImageData = await api.findClosestMatch({sampleDisctriptors: Array.from(descriptor)})
      if(dbImageData.result) {
        label = dbImageData.label;
      }
    }
  }

  const detect = async () => {
    try {
      if (
        typeof webcamRef.current !== "undefined" &&
        webcamRef.current !== null &&
        webcamRef.current.video.readyState === 4
      ) {
        // Get Video Properties
        const video = webcamRef.current.video;
  
        const displaySize = { width: 640, height: 480 };
        faceapi.matchDimensions(canvasRef.current, displaySize);
        const options = new faceapi.SsdMobilenetv1Options({ minConfidence: 0.6 })
  
        const detection = await faceapi
          .detectSingleFace(video, options)
          .withFaceLandmarks()
          .withFaceDescriptor();
        descriptor = detection && detection.descriptor;
        await drawResults(video, canvasRef.current, detection, 'boxLandmarks', label);
      }
    } catch(error) {
      console.log(error);
    }
  };

  const drawResults = async (image, canvas, results, type, label = 'unknown') => {
    if (image && canvas && results) {
      const imgSize = image.getBoundingClientRect();
      const displaySize = { width: imgSize.width, height: imgSize.height };
      faceapi.matchDimensions(canvas, displaySize);
      canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
      const resizedDetections = faceapi.resizeResults(results, displaySize);
  
      switch (type) {
        case 'landmarks':
          faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);
          break;
        case 'expressions':
          faceapi.draw.drawFaceExpressions(canvas, resizedDetections);
          break;
        case 'box':
          faceapi.draw.drawDetections(canvas, resizedDetections);
          break;
        case 'boxLandmarks':
          faceapi.draw.drawDetections(canvas, resizedDetections);
          // faceapi.draw.drawFaceExpressions(canvas, resizedDetections);
          // faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);
          //console.log(resizedDetections.alignedRect._box)
          const drawLabel = new faceapi.draw.DrawBox({x: resizedDetections.alignedRect._box._x, y: resizedDetections.alignedRect._box._y + 10, height: 0, width: 0}
            , { label });
          drawLabel.draw(canvas);
          break;
        default:
          break;
      }
    }
  }

  useEffect(()=>{
    runFacemesh();
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <Webcam
          ref={webcamRef}
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            zindex: 9,
            width: 640,
            height: 480,
          }}
        />
         <canvas
          ref={canvasRef}
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            zindex: 9,
            width: 640,
            height: 480,
          }}
        />
      </header>
    </div>
    
  );
}

export default FaceRecognition;