import React, { useRef, useEffect, useState } from 'react';
import { Input, Space, Menu, Dropdown, Button } from 'antd';
import JSMpeg from '@cycjimmy/jsmpeg-player';
import api from '../api';
import { VideoCameraAddOutlined } from '@ant-design/icons';
//const baseUrl = 'ws://localhost:';
// const baseUrl = 'wss://backend-gateway.garun.in/cam-stream/';
const baseUrl = 'wss://backend-image-processing.garun.in/cam-stream/';

const { Search } = Input;

export default function CamFeed() {
  const [loading, setLoading] = useState(false);
  const canvasRef = useRef(null);
  const [menu, setMenu] = useState(<Menu></Menu>);
  let player = null;

  const suffix = (
    <VideoCameraAddOutlined
      style={{
        fontSize: 16,
        color: '#1890ff',
      }}
    />
  );

  const updateUrl = async function (value) {
    try {
      setLoading(true);
      await api.addUrl({ url: value });
      alert('url updated successfully.');
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getAllLinks = async function () {
    try {
      let links = await api.getAllUrl();
      let urls = links.map((val) => (
        <Menu.Item key={val}>
          <Button onClick={() => startStream(val.rtsp_link)}>
            {`${val.rtsp_link}`}
          </Button>
        </Menu.Item>
      ));
      setMenu(<Menu>{urls}</Menu>);
    } catch (error) {}
  };

  const startStream = function (url) {
    try {
      if (url) {
        (async () => {
          if (player && player.stop) {
            player.stop();
          }
          const port = await api.getStreamPort({ url });
          player = new JSMpeg.Player(`${baseUrl}${port}`, {
            canvas: document.getElementById('canvas'),
          });
          console.log(`${baseUrl}${port}`);
        })();
      }
      return <a></a>;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // new JSMpeg.Player('ws://localhost:9999', {
    //         canvas: document.getElementById('canvas')
    //     })
  }, []);

  return (
    <Space direction="vertical">
      <Search
        placeholder="Enter RTSP url"
        enterButton="ADD"
        size="large"
        suffix={suffix}
        onSearch={updateUrl}
        loading={loading}
      />
      <Dropdown overlay={menu} trigger={['click']}>
        <a onClick={getAllLinks}>
          GET feeds added <VideoCameraAddOutlined />
        </a>
      </Dropdown>
      <canvas
        id="canvas"
        ref={canvasRef}
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          left: 0,
          right: 0,
          textAlign: 'center',
          zindex: 0,
          width: 640,
          height: 480,
        }}
      />
    </Space>
  );
}
