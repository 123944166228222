
import './App.css';
import Dashboard from './screens/Dashboard';


function App() {
  return (<Dashboard></Dashboard>)
}

export default App;
