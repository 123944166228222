import React, {useState} from 'react';
import { Input, Space } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';
import api from '../api';

const { Search } = Input;

export default function AadhaarVerification() {

const [otpInputDisabled, setOtpInputDisabled] = useState(true);
const [aadhaarInputDisabled, setAadhaarInputDisabled] = useState(false);
const [aadhaarLoading, setAadhaarLoading] = useState(false);
const [otpLoading, setOtpLoading] = useState(false);
const [clientId, setClientId] = useState('');


const sendOTP = async function(value) {
    try {
        setAadhaarLoading(true);
        setAadhaarInputDisabled(true);
        let data = await api.sendOTP({aadhaar_number: value});
        data = JSON.parse(data);
        setClientId(data && data.data && data.data.client_id);
        alert('otp sent please check your registered mobile number.');
        setAadhaarLoading(false);
        setOtpInputDisabled(false);
    } catch(error) {
        setAadhaarInputDisabled(false);
        setAadhaarLoading(false);
    }
}

const verifyOTP = async function(value) {
    try {
        setOtpInputDisabled(true);
        setOtpLoading(true);
        let data = await api.verifyOTP({otp: value, client_id: clientId});
        alert(`Congratulations! ${data.full_name} you have been verified on our platform. We will be using this data to train our models and get better in recognising you and make your life easy.`);
        setOtpLoading(false);
    } catch(error) {
        setAadhaarInputDisabled(false);
        setAadhaarLoading(false);
        setOtpInputDisabled(false);
        setOtpLoading(false);
    }
}

const suffix = (
  <IdcardOutlined
    style={{
      fontSize: 16,
      color: '#1890ff',
    }}
  />
);
    
return (<Space direction="vertical">
  <Search
    placeholder="Enter Aadhaar Number"
    enterButton="Send OTP"
    size="large"
    suffix={suffix}
    onSearch={sendOTP}
    disabled={aadhaarInputDisabled}
    loading={aadhaarLoading}
  />
  <Search
    placeholder="Enter OTP"
    enterButton="Verify"
    size="large"
    disabled={otpInputDisabled}
    loading={otpLoading}
    onSearch={verifyOTP}
  />
</Space>);
}
