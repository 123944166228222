import React from 'react';
import Lottie from "react-lottie";
import evCar from '../assets/animations/electric-car.json';
import logo from '../assets/images/logo.png';
import smartCity from '../assets/animations/smart-city.json';
import image from '../assets/animations/image.json';


export default function home() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: evCar,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
      const defaultOptions2 = {
        loop: true,
        autoplay: true,
        animationData: smartCity,
        // rendererSettings: {
        //   preserveAspectRatio: "xMidYMid slice"
        // }
      };
      const defaultOptions3 = {
        loop: true,
        autoplay: true,
        animationData: image,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
      return (
        <div className="App">
          <img style={{height: '200px', width: '200px'}} src={logo} alt="Logo" />
          <div style={{display:'flex', 'flex-direction':'row'}}>
          {/* <Lottie 
            options={defaultOptions}
            height={400}
            width={400}
          /> */}
          <Lottie 
            options={defaultOptions2}
            height={500}
            width={500}
          />
          {/* <Lottie 
            options={defaultOptions3}
            height={400}
            width={400}
          /> */}
          </div>
        </div>
      );
}
