// const baseUrl = 'http://localhost:3000';
//const baseUrl = 'http://3.108.223.36:3000'
// const baseUrl = 'https://backend-gateway.garun.in';
const baseUrl = 'https://backend-image-processing.garun.in';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  findClosestMatch: async function ({ sampleDisctriptors }) {
    let resp = await apicall({
      url: `${baseUrl}/demo/find-closest-match`,
      data: { sampleDisctriptors },
    });
    return resp.data;
  },
  sendOTP: async function ({ aadhaar_number }) {
    let resp = await apicall({
      url: `${baseUrl}/demo/aadhaar-generate-otp`,
      data: { aadhaar_number },
    });
    return resp.data;
  },
  verifyOTP: async function ({ client_id, otp }) {
    let resp = await apicall({
      url: `${baseUrl}/demo/aadhaar-verify-otp`,
      data: { client_id, otp },
    });
    return resp.data;
  },
  addUrl: async function ({ url }) {
    let resp = await apicall({
      url: `${baseUrl}/demo/rtsp/url`,
      data: { url },
    });
    return resp.data;
  },
  getAllUrl: async function () {
    let resp = await apicall({
      url: `${baseUrl}/demo/rtsp/url`,
      method: 'GET',
    });
    return resp.data;
  },
  getStreamPort: async function ({ url }) {
    let resp = await apicall({
      url: `${baseUrl}/demo/rtsp/stream/port`,
      data: { url },
    });
    return resp.data;
  },
};

async function apicall({ method = 'POST', url, data = null }, token) {
  try {
    let resp = await fetch(url, {
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
      ...(data ? { body: JSON.stringify(data) } : {}),
    });
    if (resp.status != 200 && resp.status != 304) {
      let data = await resp.json();
      alert(data.message || 'something went wrong');
      throw data.message || 'something went wrong.';
    }
    return resp.json();
  } catch (error) {
    throw error;
  }
}
